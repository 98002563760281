var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.classroomList.length > 0
        ? [
            _c(
              "div",
              {
                staticClass: "reg-un-evaluation",
              },
              _vm._l(_vm.classroomList, function (classroomData) {
                return _c(
                  "div",
                  {
                    key: classroomData.class_applications,
                  },
                  [
                    _c("vip-classroom", {
                      attrs: {
                        show_consultant_img: false,
                        classroom_type: "evaluations",
                        classroomData: classroomData,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "reg-evaluayion-all-finished",
              },
              [_vm._v(_vm._s(_vm.$t("empty_un_evaluation_class")))]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }